<template>
    <svg width="100%" height="100%" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
        <g id="Regular-S" transform="matrix(0.242942,0,0,0.242942,1.72716,20.5647)">
            <g id="Layer2">
                <g id="Regular-S1" serif:id="Regular-S">
                    <path d="M22.022,5.908L62.5,5.908C70.703,5.908 74.805,1.758 74.805,-6.543L74.805,-40.576C74.805,-45.947 74.219,-48.291 70.85,-51.758L50.586,-72.461C47.412,-75.732 44.775,-76.416 40.088,-76.416L22.022,-76.416C13.818,-76.416 9.766,-72.266 9.766,-63.965L9.766,-6.543C9.766,1.807 13.818,5.908 22.022,5.908ZM22.363,-1.123C18.701,-1.123 16.797,-3.125 16.797,-6.641L16.797,-63.867C16.797,-67.383 18.701,-69.385 22.412,-69.385L38.867,-69.385L38.867,-47.656C38.867,-42.578 41.455,-39.99 46.533,-39.99L67.725,-39.99L67.725,-6.641C67.725,-3.125 65.82,-1.123 62.158,-1.123L22.363,-1.123ZM47.363,-46.582C45.996,-46.582 45.41,-47.168 45.41,-48.535L45.41,-68.066L66.406,-46.582L47.363,-46.582Z" style="fill-rule:nonzero;"/>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: "IconSfDoc24",

    data() {
        return {};
    },
};
</script>