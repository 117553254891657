<template>
    <svg width="100%" height="100%" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M11.994,21c-4.907,0 -9,-4.082 -9,-9c0,-4.918 4.082,-9 8.989,-9c4.918,0 9.023,4.082 9.023,9c0,4.918 -4.093,9 -9.012,9Zm-4.394,-8.119l8.8,0l-0.012,-0.859l0.012,-0.881l-8.8,0l0,1.74Z"/></svg>
</template>

<script>
export default {
    name: "IconTheft24",

    data() {
        return {};
    },
};
</script>
