<template><!-- https://fonts.google.com/icons?icon.query=qr&selected=Material+Symbols+Outlined:qr_code:FILL@0;wght@400;GRAD@0;opsz@24 -->
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
        <path d="M120-520v-320h320v320H120Zm80-80h160v-160H200v160Zm-80 480v-320h320v320H120Zm80-80h160v-160H200v160Zm320-320v-320h320v320H520Zm80-80h160v-160H600v160Zm160 480v-80h80v80h-80ZM520-360v-80h80v80h-80Zm80 80v-80h80v80h-80Zm-80 80v-80h80v80h-80Zm80 80v-80h80v80h-80Zm80-80v-80h80v80h-80Zm0-160v-80h80v80h-80Zm80 80v-80h80v80h-80Z"/>
    </svg>
</template>

<script>
export default {
    name: "IconQrCode",

    props: {
        color: {
            type: String,
            required: false,
        },
    },

    data() {
        return {};
    },
};
</script>
