<template>
    <svg width="100%" height="100%" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
        <rect id="Artboard1" x="0" y="0" width="24" height="24" style="fill:none;"/>
        <g id="Regular-S" transform="matrix(0.229596,0,0,0.229596,1.8328,20.7368)">
            <g id="Layer1">
                <g id="Regular-S1" serif:id="Regular-S">
                    <path d="M32.91,-56.69L22.217,-56.69C13.916,-56.69 9.766,-52.588 9.766,-44.385L9.766,-6.836C9.766,1.367 13.916,5.469 22.217,5.469L66.016,5.469C74.365,5.469 78.467,1.367 78.467,-6.836L78.467,-44.385C78.467,-52.588 74.365,-56.69 66.016,-56.69L55.273,-56.69L55.273,-49.609L65.918,-49.609C69.385,-49.609 71.436,-47.705 71.436,-44.043L71.436,-7.178C71.436,-3.467 69.385,-1.611 65.918,-1.611L22.314,-1.611C18.799,-1.611 16.797,-3.467 16.797,-7.178L16.797,-44.043C16.797,-47.705 18.799,-49.609 22.314,-49.609L32.91,-49.609L32.91,-56.69Z"/>
                    <path d="M44.092,-24.805C45.996,-24.805 47.607,-26.367 47.607,-28.223L47.607,-66.309L47.315,-72.168L49.365,-69.922L54.736,-64.111C55.371,-63.428 56.25,-63.086 57.08,-63.086C58.935,-63.086 60.303,-64.356 60.303,-66.113C60.303,-67.09 59.912,-67.773 59.228,-68.408L46.631,-80.469C45.752,-81.348 45.02,-81.641 44.092,-81.641C43.213,-81.641 42.481,-81.348 41.553,-80.469L28.955,-68.408C28.32,-67.773 27.93,-67.09 27.93,-66.113C27.93,-64.356 29.248,-63.086 31.104,-63.086C31.934,-63.086 32.861,-63.428 33.496,-64.111L38.867,-69.922L40.918,-72.168L40.625,-66.309L40.625,-28.223C40.625,-26.367 42.236,-24.805 44.092,-24.805Z" style="fill-rule:nonzero;"/>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: "IconSfShare24",

    // props: {
    //     color: {
    //         type: String,
    //         required: false,
    //     },
    // },

    data() {
        return {};
    },
};
</script>