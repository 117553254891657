<template>
    <div class="icon-wrapper" :class="[classIcon]">
      <chevron v-if="iconType == 'chevron-left'"  :iconDirection="'left'" />
      <component v-else :is="platformIconType" />
    </div>
</template>

<script>

import { mapGetters } from "vuex";

import IconProximity24 from "@/components/svg/proximity24.vue";
import IconDownloadAndroid from "@/components/svg/IconDownloadAndroid.vue";
import IconQrCode from "@/components/svg/IconQrCode.vue";
import IconSfShare24 from "@/components/svg/IconSfShare24.vue";
import IconSfDoc24 from "@/components/svg/IconSfDoc24.vue";
import IconMaterialDraft from "@/components/svg/IconMaterialDraft.vue";
import IconLiveTracking24 from "@/components/svg/IconLiveTracking24.vue";
import IconTheft24 from "@/components/svg/IconTheft24.vue";
import IconMinusCircleFill24 from "@/components/svg/IconMinusCircleFill24.vue";
import Chevron from '@/svg/chevron.vue';

export default {
    name: "IconWrapper",
    
    components: {
        IconProximity24,
        IconDownloadAndroid,
        IconQrCode,
        IconSfShare24,
        IconSfDoc24,
        IconMaterialDraft,
        IconLiveTracking24,
        IconTheft24,
        IconMinusCircleFill24,
        Chevron,
    },

    computed: {
      ...mapGetters("appcompat", ["hostOS"]),

      platformIconType() {
        if (this.iconType == "icon-share") {
          if (this.hostOS == 'Android') {
            return 'icon-download-android';
          }
          else {
            return 'icon-sf-share24';
          }
        }
        else if (this.iconType == "icon-doc") {
          if (this.hostOS == 'Android') {
            return 'icon-material-draft';
          }
          else {
            return 'icon-sf-doc24';
          }
        }
        else if (this.iconType == "icon-bg-theft48") {
          return 'icon-theft24';
        }
        else if (this.iconType == "icon-delete-cell") {
          return 'icon-minus-circle-fill24'
        }
        
        else {
          return this.iconType;
        }
      },

      classIcon() {
        return this.iconType;
      }

    },
    
    props: {
        iconType: {
            type: String,
            required: true,
        },

        width: {
          type: String,
          required: false,
        },

    },
    
    data() {
        return {};
    },
};


</script>

<style lang="scss">
.icon-wrapper {
    display: flex;
    width: 24px;
    height: 24px;
    border-radius: 0px;
    justify-content: center;
    align-items: center;
    min-width: 24px;
}

.icon-proximity24 {
//   background-color: #E0F0FF;

  svg {
    fill: #3579f6;
  }
}

.icon-live-tracking24 {
  svg {
    fill: #9d4edd;
  }
}

.icon-download-android {
//   background-color: #E0F0FF;

  svg {
    fill: #3579f6;
  }
}

.icon-share {
  svg {
    fill: #3579f6;
  }
}

.icon-doc {
  svg {
    fill: #3579f6;
  }
}

// .icon-material-draft {
//   svg {
//     fill: #3579f6;
//   }
// }

.icon-qr-code {
  svg {
    fill: #212529; // TODO: understand coloroverride, does not work in tag inside an action button
  }
}

.icon-bg-theft48 {
  background-color: #3579f6;
  border-radius: 4px;
  width: 48px;
  height: 48px;
  min-width: 48px;

  svg {
    fill: #E0F0FF;
  }
}

.icon-theft24 {

  svg {
    fill: #3579f6;
  }
}

.icon-delete-cell {
  svg {
    fill: #D83F31;
    //fill: #f95658; //(TRK.red)
  }
}

</style>