<template>
    <button @click="target" class="nvx-cellule">
        <icon-wrapper v-if="icon != null" :iconType="icon" />
        <div class="cell-container">
            <slot/>
        </div>
        <chevron :iconSize="14" />
    </button>
</template>

<script>

import Chevron from "@/svg/chevron.vue";
import IconWrapper from "@/components/ui/IconWrapper.vue";

export default {
    name: "NvxButtonCell",

    components: { 
        IconWrapper,
        Chevron 
    },

    props: {
        icon: {
            type: String,
            required: false,
        },
        target: {
            type: Function,
            required: true,
        }
    },

    data() {
        return {};
    }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";
@import "@/assets/scss/appmenus/native.scss";


</style>