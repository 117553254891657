<template>
<svg width="100%" height="100%" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <rect id="Artboard1" x="0" y="0" width="24" height="24" style="fill:none;"/>
    <g id="Artboard11" serif:id="Artboard1">
        <g id="proximite-20" transform="matrix(0.869565,0,0,0.869565,2.00009,1.99966)">
            <path id="proximite-24_2" d="M13.225,11.5C13.225,12.453 12.453,13.225 11.5,13.225C10.547,13.225 9.775,12.453 9.775,11.5C9.775,10.548 10.547,9.775 11.5,9.775C12.453,9.775 13.225,10.548 13.225,11.5Z" />
            <g transform="matrix(0.333333,0,0,0.333333,-0.5,-0.5)">
                <path d="M56.7,36C56.7,47.432 47.432,56.7 36,56.7C24.567,56.7 15.3,47.432 15.3,36C15.3,24.568 24.567,15.3 36,15.3C47.432,15.3 56.7,24.568 56.7,36ZM52.2,36C52.2,27.053 44.947,19.8 36,19.8C27.053,19.8 19.8,27.053 19.8,36C19.8,44.947 27.053,52.2 36,52.2C44.947,52.2 52.2,44.947 52.2,36Z"/>
            </g>
            <g id="proximite-24_21" serif:id="proximite-24_2" transform="matrix(0.333333,0,0,0.333333,-0.5,-0.5)">
                <path d="M70.5,36.001C70.5,55.055 55.054,70.501 36,70.501C16.946,70.501 1.5,55.055 1.5,36.001C1.5,16.947 16.946,1.501 36,1.501C55.054,1.501 70.5,16.947 70.5,36.001ZM64.5,36.001C64.5,20.261 51.74,7.501 36,7.501C20.259,7.501 7.5,20.261 7.5,36.001C7.5,51.741 20.259,64.501 36,64.501C51.74,64.501 64.5,51.741 64.5,36.001Z"/>
            </g>
        </g>
    </g>
</svg>


</template>

<script>
export default {
    name: "IconProximity24",

    props: {
        color: {
            type: String,
            required: false,
        },
    },

    data() {
        return {};
    },
};
</script>

<style lang="scss"></style>