<template>
    <svg width="100%" height="100%" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g transform="matrix(1,0,0,1,5.2,1.99999)">
        <path d="M4.575,19.629L13.349,9.166C13.513,8.978 13.6,8.799 13.6,8.594C13.6,8.254 13.31,7.994 12.905,7.994L7.452,7.994L10.328,1.031C10.714,0.109 9.652,-0.392 9.025,0.378L0.251,10.831C0.087,11.028 0,11.207 0,11.404C0,11.753 0.29,12.012 0.695,12.012L6.148,12.012L3.272,18.966C2.886,19.888 3.948,20.389 4.575,19.629ZM8.378,10.679L2.413,10.679L7.857,3.976L5.222,9.327L11.177,9.327L5.733,16.031L8.378,10.679Z" style="fill:rgb(113,84,227);fill-rule:nonzero;"/>
    </g>
</svg>
</template>

<script>
export default {
    name: "IconLiveTracking24",

    data() {
        return {};
    },
};
</script>