<template>
    <div class="ios-section">
        <h3 v-if="header!=null">{{ $t(header) }}</h3>
        <div class="section-container">
            <slot/>
        </div>
        <span v-if="footer!=null" class="footer">{{ $t(footer) }}</span>
    </div>
</template>

<script>

export default {
    name: "NvxSection",

    props: {
        header: {
            type: String,
            required: false,
        },
        footer: {
            type: String,
            required: false,
        },
    },

    data() {
        return {};
    }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

@import "@/assets/scss/appmenus/native.scss";


</style>