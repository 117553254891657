<template>
    <svg width="100%" height="100%" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><g id="Artboard1"><g><g id="Regular-S"><path d="M13.765,6.391c1.033,-0 1.852,-0.831 1.852,-1.852c-0,-1.032 -0.819,-1.851 -1.852,-1.851c-1.021,-0 -1.851,0.819 -1.851,1.851c-0,1.021 0.83,1.852 1.851,1.852Zm-2.893,7.362l3.223,1.819l-2.085,1.777c-0.457,0.404 -0.426,0.904 -0.16,1.202c0.277,0.309 0.776,0.405 1.256,-0.01l2.915,-2.49c0.404,-0.329 0.34,-1.074 -0.149,-1.394l-3.16,-2.074l0.617,-2.182c0.085,-0.308 0.447,-0.351 0.628,-0.084l0.925,1.362c0.224,0.318 0.628,0.447 0.99,0.276l2.681,-1.191c0.489,-0.213 0.67,-0.64 0.458,-1.075c-0.213,-0.426 -0.628,-0.554 -1.097,-0.341l-2.127,0.936l-1.458,-2.021c-0.766,-1.043 -1.703,-1.479 -3.266,-1.362c-0,-0 -0.671,1.863 -0.766,2.192l-0.681,2.426c-0.415,1.436 0.521,1.819 1.256,2.234Zm-3.218,6.87c0.441,0.167 0.921,0.025 1.137,-0.594c-0,-0 0.676,-2.084 0.814,-2.435l1.267,-3.841c-0,0 -0.948,-1.851 -1.256,-2.234l-1.554,5.415l-0.73,2.605c-0.239,0.758 0.014,0.966 0.322,1.084Z" style="fill-rule:nonzero;"/></g><path d="M10.304,7.22c-0.035,0.976 -1.282,5.128 -1.976,5.754c-1.021,0.92 -2.938,-0.733 -3.199,-1.96c-0.377,-1.766 2.355,-2.441 3.876,-3.048"/></g></g></svg>
</template>

<script>
export default {
    name: "IconTheft24",

    data() {
        return {};
    },
};
</script>